<template>
  <div class="revenueRecords">
    <app-header :title="title" :isShowBack="false"></app-header>
    <div class="revenueRecords-content" v-if="merchantFlag">
      <div class="item">
        <div class="item-num">{{dodayOrderNum}}</div>
        <div>今日订单（个）</div>
      </div>
      <div class="item">
        <div class="item-num">{{dodayAccount}}</div>
        <div>今日收益（元）</div>
      </div>
      <div class="item">
        <div class="item-num">{{dodayGoodsNum}}</div>
        <div>今日售卖商品（件）</div>
      </div>
      <div class="item">
        <div class="item-num">{{allOrderNum}}</div>
        <div>累计收益订单（个）</div>
      </div>
      <div class="item">
        <div class="item-num">{{sumAccount}}</div>
        <div>累计收益（元）</div>
      </div>
    </div>
    <div :class="merchantFlag ? 'revenueRecords-list-merchant' : 'revenueRecords-list'">
      <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
    </div>
    <!-- <el-dialog title="结算确认" :visible.sync="dialogVisible" width="720px" top="25vh" :close-on-click-modal="false">
      <div class="account-content">线下结算的订单，请先完成线下转账，再手动修改结算状态。确定手动结算吗？</div>
      <div>
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="结算给商家"></el-checkbox>
          <el-checkbox label="结算给供应商"></el-checkbox>
          <el-checkbox label="结算给经销商商"></el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="dialog-footer">
        <el-button type="text">取消</el-button>
        <el-button type="text" @click="submit()">确定</el-button>
      </div>
    </el-dialog> -->
  </div>
</template>
<script>
  let validItemsName = (rule, value, callback) => {
      if (value == "" || value == undefined) {
          callback();
      } else {
          //仅支持输入数字、汉字，大小写字母，限制字符长度1~32个字符
          let reg = /^[a-zA-Z0-9_\u4e00-\u9fa5]{1,32}$/;
          if (!reg.test(value)) {
              callback(new Error("商品名称格式不对或超过32个字符"));
          } else {
              callback();
          }
      }
  };
  import cabinetUtil from "@utils/cabinetUtil.js"
  export default {
    data() {
      let _this = this;
      return {
        title: "商家收益记录",
        // dialogVisible: false,
        // checkList:[],
        opt: {
          search: [{
              key: "merchantId",
              label: "商家名称",
              type: 'remoteSearchById',
              opt: {
                list: [],
                change(data) {
                  _this.getStoreList(data)
                }
              }
            }, 
            {
              key: "groupId",
              label: "门店",
              type: "remoteSearchById",
              opt: {
                list: []
              }
            },
            {
              label: "商品名称",
              key: 'itemsName',
              rules: [{
                  validator: validItemsName,
                  trigger: ['blur']
              }]
            },
            {
              key: "id",
              label: "订单编号",
            },
            {
              key: "subOrderNo",
              label: "结算单号",
            },
            // {
            //   key: "close",
            //   label: "结算方式",
            //   type: 'select',
            //   opt: {
            //     list: [
            //       {
            //         value: 1,
            //         label: '线上结算'
            //       },
            //       {
            //         value: 2,
            //         label: '线下结算'
            //       }
            //     ],
            //   }
            // },
            {
              key: "closeState",
              label: "结算状态",
              type: 'select',
              opt: {
                list: [
                  // {
                  //   value: 0,
                  //   label: '未结算'
                  // },
                  {
                    value: 1,
                    label: '已结算'
                  },
                  {
                    value: 2,
                    label: '结算中'
                  }
                ],
              }
            },
            {
              key: "date",
              label: "结算时间",
              type: "data-range"
            }
          ],
          columns: [{
              label: "订单编号（主）",
              key: "id",
              align: "center",
              width: 150
            },
            {
              label: "结算单号",
              key: "subOrderNoStr",
              align: "center",
            },
             {
              label: "商品名称",
              key: "itemName",
              align: "center",
              opt: {
                  isUserPopover: true
              }
            },
            {
              label: "商家名称",
              key: "merchantName",
              align: "center",
            },
            {
              label: "门店名称",
              key: "groupName",
              align: "center",
            },
            {
              label: "订单支付时间",
              key: "payTime",
              align: "center",
              width: 120
            },
            {
              label: "订单总金额",
              key: "totalAmountStr",
              align: "center",
              width: 100
            },
            {
              label: "实际支付金额",
              key: "realPayAmountStr",
              align: "center",
              width: 120
            },
            {
              label: "结算金额",
              key: "settleAccount",
              align: "center"
            },
            {
              label: "平台佣金",
              key: "brokerage",
              align: "center"
            }, {
              label: "手续费",
              key: "fee",
              align: "center"
            },
            {
              label: "商家收益",
              key: "merchantFee",
              align: "center"
            },
            {
              label: "供应商收益",
              key: "supplierFee",
              align: "center",
              width: 100
            },
            {
              label: "经销商收益",
              key: "distributorFee",
              align: "center",
              width: 100
            },
            {
              label: "结算时间",
              key: "closeTime",
              align: "center"
            },
            // {
            //   label: "结算方式",
            //   key: "closeStateStr",
            //   align: "center"
            // },
            {
              label: "结算状态",
              key: "closeStateStr",
              align: "center",
              // type: "actionStatus",
              // opt: {
              //   ativeStatus: "已结算",
              //   on(row, item) {
              //     _this.dialogVisible = true;
              //   }
              // }
            },
          ],
          exportButtons: [{
            type: "Export",
            url: this.EXport + "/mall-service/orders/fee/export",
            listName: '商家收益记录'
          }],
        },
        merchantFlag: false,
        // 收益统计
        dodayOrderNum: 0,
        dodayAccount: 0,
        dodayGoodsNum: 0,
        allOrderNum: 0,
        sumAccount: 0,
      };
    },
    created() {
      console.log("revenueRecords-list created!!");

    },
    async activated() {
      console.log("revenueRecords-list activated!!");
      try {
        this.merchantFlag = this.getLoginType('loginType');
        if (this.merchantFlag) {
          this.title = '收益记录';
          this.opt.search.forEach((item, index) => {
            if (item.key == 'merchantId') {
              this.opt.search.splice(index, 1);
            }
          })
          this.opt.columns.forEach((item, index) => {
            if (item.key == 'merchantName') {
              this.opt.columns.splice(index, 1);
            }
          })
          this.opt.columns.forEach((item, index) => {
            if (item.key == 'fee') {
              this.opt.columns.splice(index, 1);
            }
          })
          this.getTotalData();
        } else {
          let merchantList = await cabinetUtil.getMerchantList();
          this.opt.search.forEach((item, index) => {
            if (item.key == 'merchantId') {
              item.opt.list = merchantList;
            }
          })
        }
      } catch (err) {
        console.log(error)
      }

    },
    methods: {
      onGet(opt) {
        let dto = {
          roleType:'1',
          pageNum: opt.skip,
          pageSize: opt.limit,
          ...opt.searchForm,
          closeStartTime: opt.searchForm['date'] ? this.format(opt.searchForm['date'][0]) : null,
          closeEndTime: opt.searchForm['date'] ? moment(opt.searchForm['date'][1]).format("YYYY-MM-DD 23:59:59") : null,
        };
        if(dto.date) {
          delete dto.date
        }
        this.post("mall-service/orders/fee/page", dto, {
          isUseResponse: true
        }).then(res => {
          res.data.data = res.data.list;
          // 平台登录
          res.data.list.forEach(item => {
            item.closeStateStr = ['未结算','已结算','结算中'][item.closeState];
            item.payTimeStr = item.payTime ? item.payTime : '--';
            item.totalAmountStr = item.totalAmount ? item.totalAmount.toFixed(2) : '--';
            item.realPayAmountStr = item.realPayAmount ? item.realPayAmount.toFixed(2) : '--';
            item.merchantName = item.merchantName ? item.merchantName : '--';
            item.closeTime = item.closeTime ? item.closeTime : '--';
            item.subOrderNoStr = item.subOrderNo ? item.subOrderNo : '--'; 
            item.settleAccount = item.settleAccount != null ? Number(item.settleAccount).toFixed(2) : 0.00;
            item.brokerage = item.brokerage != null ? Number(item.brokerage).toFixed(2) : 0.00;
            item.fee = item.fee != null ? Number(item.fee).toFixed(2) : 0.00;
            item.merchantFee = item.merchantFee != null ? Number(item.merchantFee).toFixed(2) : 0.00;
            item.supplierFee = item.supplierFee != null ? Number(item.supplierFee).toFixed(2) : 0.00;
            item.distributorFee = item.distributorFee != null ? Number(item.distributorFee).toFixed(2) : 0.00;
            item.itemName = item.itemName ? item.itemName : '--';
          });

          if(this.merchantFlag) {
            // 商家登录
            let isSellOnBusinessFlag = this.isSellOnBusiness();
            console.log('isSellOnBusinessFlag:',isSellOnBusinessFlag)
            if(isSellOnBusinessFlag) {
              // 经销类商家登录 供应商收益显示--
              res.data.list.forEach(item => {
                item.supplierFee = '--';
              })
            }else {
              // 普通商家登录 经销商收益显示--
              res.data.list.forEach(item => {
                item.distributorFee = '--';
              })
            }
          }
          
          opt.cb(res.data);
        });
        if(opt.searchForm.merchantId) {
          this.getStoreList(opt.searchForm.merchantId);
        }else{
          this.getStoreList();
        }
      },
      // 收益统计
      getTotalData() {
        let dto = {
          roleType:'1',
        };
        let merchantFlag = this.getLoginType();
        if (merchantFlag) {
          dto.merchantId = this.cache.getLS('userInfo').merchantId;
        }
        this.post('/mall-service/v1/order/count/fee', dto, {
          isUseResponse: true
        }).then(res => {
          let data = res.data.data;
          if (res.data.code == 0) {
            this.dodayOrderNum = data.dodayOrderNum ? data.dodayOrderNum : 0;
            this.dodayAccount = data.dodayAccount ? Number(data.dodayAccount).toFixed(2) : 0.00;
            this.dodayGoodsNum = data.dodayGoodsNum ? data.dodayGoodsNum : 0;
            this.allOrderNum = data.allOrderNum ? data.allOrderNum : 0;
            this.sumAccount = data.sumAccount ? Number(data.sumAccount).toFixed(2) : 0.00;
          }
        })
      },
      //获取门店列表
      getStoreList(data) {
        let dto = {
          currentPage: 1,
          size: 99999,
        };
        if(data) {
          dto.merchantId = data
        }
        this.post("/mall-service/v1/counterStores/getAllList", dto, {
          isUseResponse: true,
        }).then(res => {
          let storeList = res.data.data;
          storeList = JSON.parse(JSON.stringify(storeList).replace(/groupName/g, "label"));
          storeList = JSON.parse(JSON.stringify(storeList).replace(/groupId/g, "value"));
          this.opt.search.forEach(item => {
            if (item.key == 'groupId') {
              item.opt.list = storeList;
            }
          })
        });
      },
    }
  };
</script>
<style lang="scss" scoped>
  .revenueRecords-list-merchant {
    height: calc(100% - 168px);
  }

   .revenueRecords-list {
    height: calc(100% - 60px);
  }

  .revenueRecords-content {
    border-bottom: 1px solid #cccccc;
    margin-bottom: 12px;
  }

  .item {
    display: inline-block;
    min-width: 247px;
    height: 77px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding-left: 10px;
    padding-top: 6px;
    margin-bottom: 10px;
    margin-right: 25px;
  }

  .item-num {
    font-size: 37px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .item-text {
    font-size: 12px;
  }
  .account-content{
    margin: -20px 0 20px;
  }
  .dialog-footer{
    text-align: right;
  }
</style>